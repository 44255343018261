<template>
  <div>
    <!-- 卡片式表单 -->
    <div class="card">
      <!-- 表单项 -->
      <div class="card-item">
        <div class="label">资质证书</div>
        <!-- 图片块 -->
        <div class="image-block flex-row-default-center">
          <!-- 选择图片上传按钮 -->
          <div class="add-image flex-center-center" @click="handleSelectImage('imageList1')">+</div>
          <div class="show-image" v-for="(item, index) in formData.imageList1" :key="index">
            <img class="image" :src="item" />
            <!-- 移除图片的按钮 -->
            <div class="remove-image flex-center-center" @click="handleDeleteImage('imageList1', index)">×</div>
          </div>
        </div>
      </div>

      <!-- 表单项 -->
      <div class="card-item">
        <div class="label">奖状</div>
        <!-- 图片块 -->
        <div class="image-block flex-row-default-center">
          <!-- 选择图片上传按钮 -->
          <div class="add-image flex-center-center" @click="handleSelectImage('imageList2')">+</div>
          <div class="show-image" v-for="(item, index) in formData.imageList2" :key="index">
            <img class="image" :src="item" />
            <!-- 移除图片的按钮 -->
            <div class="remove-image flex-center-center" @click="handleDeleteImage('imageList2', index)">×</div>
          </div>
        </div>
      </div>

      <!-- 表单项 -->
      <div class="card-item">
        <div class="label">其他</div>
        <!-- 图片块 -->
        <div class="image-block flex-row-default-center">
          <!-- 选择图片上传按钮 -->
          <div class="add-image flex-center-center" @click="handleSelectImage('imageList3')">+</div>
          <div class="show-image" v-for="(item, index) in formData.imageList3" :key="index">
            <img class="image" :src="item" />
            <!-- 移除图片的按钮 -->
            <div class="remove-image flex-center-center" @click="handleDeleteImage('imageList3', index)">×</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 按钮 -->
    <div class="button-one">修 改</div>

    <!-- 上传图片input -->
    <input v-show="false" ref="file" type="file" accept="image/*" @change="handleUploadImage" />
  </div>
</template>

<script>
import { uploadToOSS } from '@/request/upload'
import { Toast } from 'mint-ui'

export default {
  name: 'Certificate',

  data () {
    return {
      formData: {
        imageList1: [],
        imageList2: [],
        imageList3: []
      },
      target: ''
    }
  },

  methods: {
    // 选择图片
    handleSelectImage (target) {
      this.target = target
      this.$refs.file.click()
    },
    // 删除图片
    handleDeleteImage (target, index) {
      this.formData[target].splice(index, 1)
    },
    // 上传图片
    async handleUploadImage () {
      if (this.$refs.file.files[0]) {
        const file = this.$refs.file.files[0]
        const res = await this.api.getAliOSSToken()
        await uploadToOSS(res.data, file).then(res => {
          this.formData[this.target].push(res)
        }).catch(() => {
          Toast('上传失败')
        })
      }
    }
  }
}
</script>

<style scoped>
.card {
  margin-top: 0;
}
</style>
