import axios from 'axios'

// 上传到oss
export async function uploadToOSS (token, file) {
  // 在手机上没有path这个字段会出错，因此使用随机名不加后缀
  const key = token.dir + Date.now() + file.name.substring(file.name.lastIndexOf('.'))

  const formData = new FormData()
  formData.append('filename', key)
  formData.append('OSSAccessKeyId', token.accessid)
  formData.append('key', key)
  formData.append('success_action_status', '200')
  formData.append('success_action_status', '200')
  formData.append('dir', token.dir)
  formData.append('expire', token.expire)
  formData.append('host', token.host)
  formData.append('policy', token.policy)
  formData.append('signature', token.signature)
  formData.append('file', file)

  return axios({
    method: 'post',
    url: token.host,
    headers: {
      'content-type': 'multipart/form-data'
    },
    processData: false,
    contentType: false,
    data: formData
  }).then(() => token.host + key)
}
